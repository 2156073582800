iframe {
  pointer-events: none;
}

input:focus-visible,
textarea:focus-visible,
select:focus-visible {
  outline: none !important;
}
.google-autocomplete-input [type="text"]:focus {
  --tw-ring-color: transparent !important;
}

.site-sidabar {
  width: 340px;
}

.logo-bar {
  margin-bottom: 30px;
}

.logo-sidebar-container {
  @apply w-full pl-10 lg:pl-0 border-r border-white border-opacity-30;
  @apply lg:flex lg:items-center lg:justify-center;
}

.logo-sidebar-header {
  width: 173px !important;
  height: 46px !important;
}

.logo-bar svg {
  width: 189px;
}

.main-wrapper {
  width: 100%;
  max-width: 100%;
  padding: 41px;
}

.sidebar-link {
  padding-left: 40px;
  @apply py-4 text-lg font-medium;
}

.sidebar-link:hover svg path,
.sidebar-link.is-active svg path {
  fill: #0c6ed1;
}

.sidebar-icon-bg {
  width: 35px;
  height: 35px;
}

.text-decoration {
  text-decoration-color: #ec5627 !important;
  text-decoration-style: solid !important;
  text-decoration-thickness: 2px !important;
  text-underline-offset: 20px !important;
}

.flex-middle {
  @apply flex;
  @apply justify-center;
  @apply items-center;
}

.site-header {
  padding: 0 29px;
}
.site-header .profile-button {
  width: 67.39px;
  height: 51.09px;
  border-radius: 100%;
}

.profile-name .profile-name-text {
  /* font-family: Larsseit; */
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
}

.alert {
  @apply bg-secondary;
  @apply justify-between;
  @apply flex;
  @apply items-center;
  @apply gap-4;
  @apply text-white;
  padding: 17px 29px;
}

.alert svg path {
  fill: white;
}
.alert-button {
  width: 27px;
  height: 27px;
  border-radius: 100%;
  @apply bg-primary;
}

.close {
  @apply w-5 h-5 opacity-40;
  cursor: pointer;
  content: url("./assets/svg/close.svg");
}

.close-video {
  cursor: pointer;
  content: url("./assets/svg/close-video.svg");
  width: 12px;
  height: 12px;
}

.drop-shadow-2 {
  filter: drop-shadow(0px 16px 32px rgba(35, 23, 5, 0.26)) !important;
}

.bg-size-full {
  background-size: 100% 100%;
}

.icon-container-stats {
  width: 48px !important;
  height: 48px !important;
  background: #f0f0f0 !important;
  @apply rounded-full flex justify-center items-center;
}
.notification-panel {
  min-width: 500px;
  height: 100vh;
  padding: 32px 50px;
}

.transaction-panel {
  min-width: 686px;
  height: 100vh;
  background: #f8f8fb;
  border: 1px solid #ececf5;
  box-shadow: 0px 4px 20px rgba(53, 63, 111, 0.12);
}

.account-panel {
  min-width: 500px;
  height: 100vh;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  box-shadow: 0px 4px 20px rgba(53, 63, 111, 0.12);
}

.account-avatar {
  width: 70px !important;
  height: 70px !important;
  @apply rounded-full;
}

.transaction-header {
  height: 98px !important;
  @apply px-8 pl-9 pr-3;
}

.transaction-content {
  background: #fffff !important;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.07) !important;
  @apply p-10;
}

.list-style {
  list-style: none !important;
}

.list-style li:before {
  content: "✓";
  padding-right: 10px;
  color: #4ed988;
}

.bg-footer-transaction {
  @apply px-10 py-3;
  background: rgba(232, 232, 232, 0.5) !important;
}

input:checked ~ .dot {
  transform: translateX(100%);
  background-color: white;
}

.notification-dropbox {
  @apply bg-notification-dropbox border-dotted border-notification-border-gray border-2 w-full rounded-md flex items-center justify-center;
  height: 140px;
}

.ql-editor {
  min-height: 450px !important;
}

.ql-toolbar {
  border: 1px solid #e6e6e6 !important;
  border-radius: 4px 4px 0px 0px !important;
  background: #f5f5f5 !important;
}

.ql-container {
  border: 1px solid #e6e6e6 !important;
  border-radius: 0px 0px 4px 4px !important;
}

input[type="file"] {
  display: none;
}

.tracking-very-tight {
  letter-spacing: -0.03em !important;
}

.text-10 {
  font-size: 11px !important;
  line-height: 156% !important;
}

.logo-invoice {
  width: 150px;
  height: 40px;
}

/*modal-animation*/
@keyframes appear {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.modal-animation {
  animation: appear 350ms ease-in 1;
}
