@font-face {
  font-family: "Circular Std";
  src: url("./assets/fonts/shellypalmercircularstdblack.eot");
  src: url("./assets/fonts/shellypalmercircularstdblack.eot")
      format("embedded-opentype"),
    url("./assets/fonts/shellypalmercircularstdblack.woff2") format("woff2"),
    url("./assets/fonts/shellypalmercircularstdblack.woff") format("woff"),
    url("./assets/fonts/shellypalmercircularstdblack.ttf") format("truetype"),
    url("./assets/fonts/shellypalmercircularstdblack.svg#shellypalmercircularstdblack")
      format("svg");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Circular Std";
  src: url("./assets/fonts/shellypalmercircularstdbold.eot");
  src: url("./assets/fonts/shellypalmercircularstdbold.eot")
      format("embedded-opentype"),
    url("./assets/fonts/shellypalmercircularstdbold.woff2") format("woff2"),
    url("./assets/fonts/shellypalmercircularstdbold.woff") format("woff"),
    url("./assets/fonts/shellypalmercircularstdbold.ttf") format("truetype"),
    url("./assets/fonts/shellypalmercircularstdbold.svg#shellypalmercircularstdbold")
      format("svg");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Circular Std";
  src: url("./assets/fonts/shellypalmercircularstdbook.eot");
  src: url("./assets/fonts/shellypalmercircularstdbook.eot")
      format("embedded-opentype"),
    url("./assets/fonts/shellypalmercircularstdbook.woff2") format("woff2"),
    url("./assets/fonts/shellypalmercircularstdbook.woff") format("woff"),
    url("./assets/fonts/shellypalmercircularstdbook.ttf") format("truetype"),
    url("./assets/fonts/shellypalmercircularstdbook.svg#shellypalmercircularstdbook")
      format("svg");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Circular Std";
  src: url("./assets/fonts/shellypalmercircularstdbookitalic.eot");
  src: url("./assets/fonts/shellypalmercircularstdbookitalic.eot")
      format("embedded-opentype"),
    url("./assets/fonts/shellypalmercircularstdbookitalic.woff2")
      format("woff2"),
    url("./assets/fonts/shellypalmercircularstdbookitalic.woff") format("woff"),
    url("./assets/fonts/shellypalmercircularstdbookitalic.ttf")
      format("truetype"),
    url("./assets/fonts/shellypalmercircularstdbookitalic.svg#shellypalmercircularstdbookitalic")
      format("svg");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "Circular Std";
  src: url("./assets/fonts/shellypalmercircularstdmedium.eot");
  src: url("./assets/fonts/shellypalmercircularstdmedium.eot")
      format("embedded-opentype"),
    url("./assets/fonts/shellypalmercircularstdmedium.woff2") format("woff2"),
    url("./assets/fonts/shellypalmercircularstdmedium.woff") format("woff"),
    url("./assets/fonts/shellypalmercircularstdmedium.ttf") format("truetype"),
    url("./assets/fonts/shellypalmercircularstdmedium.svg#shellypalmercircularstdmedium")
      format("svg");
  font-style: normal;
  font-weight: 500;
}

body {
  margin: 0;
  font-family: "Circular Std";
  @apply bg-siteBg;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin: 0;
}

[type="checkbox"] {
  color: #0c6ed1;
  outline: none;
  border: 2px solid #d5d5d5;
  border-radius: 4px;
}

input[type="checkbox"]:focus {
  outline: none !important;
  box-shadow: none;
}

input[type="text"],
input[type="password"],
input#react-select-address-input,
input[type="email"] {
  @apply rounded-md pb-1 pt-5 px-4
    2xl:pt-8;
  border-color: #dfdfdf;
  border-width: 1px !important;
}

textarea[type="text"] {
  @apply rounded-md px-4;
  border-color: #dfdfdf;
  border-width: 1px !important;
}

input#react-select-address-input {
  width: 100% !important;
  box-shadow: none;
}

input:focus-visible,
textarea:focus-visible,
.react-select-address-input:focus-visible,
textarea:focus-visible,
select:focus-visible {
  outline: none !important;
  border-width: 1px !important;
}

input:focus,
textarea:focus,
.react-select-address-input:focus,
textarea:focus,
select:focus {
  box-shadow: none !important;
}

textarea,
input,
input#react-select-address-input {
  outline: none;
}

input:focus-within ~ label,
input:not(:placeholder-shown) ~ label {
  /* @apply transform scale-75 -translate-y-6; */
}

input[type="text"]:focus-within ~ label,
input[type="password"]:focus-within ~ label,
input[type="email"]:focus-within ~ label,
textarea:focus-within ~ label,
input#react-select-address-input:focus-within ~ label {
  @apply text-secondary opacity-100;
}

.custom-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.custom-scroll::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}
.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #d1d1d1;
  border-radius: 10px;
}

.mobile-scroll::-webkit-scrollbar {
  width: 0px;
  height: 5px;
}
.mobile-scroll::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}
.mobile-scroll::-webkit-scrollbar-thumb {
  background-color: #d1d1d1;
  border-radius: 10px;
}

input[type="range"] {
  @apply rounded-full w-full h-1.5;
  -webkit-appearance: none;
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  @apply rounded-full;
  -webkit-appearance: none !important;
  appearance: none !important;
  width: 20px; /* Set a specific slider handle width */
  height: 20px; /* Slider handle height */
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);
  cursor: pointer; /* Cursor on hover */
}

@import "./assets/css/index.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
