/* wilson */
.account-img {
  width: 30px !important;
  height: 30px !important;
}

/* 
Custom CSS for react-datepicker by HackerOne
Original Author: 	msnegurski https://github.com/msnegurski
Edited and Added Features: Wilson Joseph Hinacay https://github.com/wilsonJosephHinacay
gray-300 - rgb(209 213 219);
gray-200 - rgb(229 231 235);
gray-800 - rgb(31 41 55);
gray-400 - rgb(156 163 175);
gray-700 - rgb(55 65 81);
blue-500 - rgb(59 130 246);
blue-200 - rgb(191 219 254);
*/
.react-datepicker__input-container input {
  @apply rounded-md px-4;
  @apply block w-full appearance-none outline-none bg-white text-base;
  border-color: #dfdfdf;
  border-width: 1px !important;
  padding-left: 1.2rem !important;
  padding-right: 2.4rem !important;
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}

.react-datepicker-popper {
  @apply z-50 w-72 text-sm bg-white shadow px-3 py-2 border-2 border-gray-200 rounded;
}

.react-datepicker-left {
  @apply absolute left-0 right-auto top-11 transform-none !important;
}

.react-datepicker-right {
  @apply absolute right-0 left-auto top-11 transform-none !important;
}

.react-datepicker__portal {
  @apply absolute z-10 w-72 text-sm transform-none bg-white shadow px-3 py-2 top-12 right-0 border-2 border-gray-200 rounded;
}

.react-datepicker__month-container {
  @apply flex flex-col;
}

.react-datepicker__month {
  @apply flex flex-col;
}

.react-datepicker__current-month {
  @apply ml-2.5 text-lg font-semibold text-gray-800;
}

.react-datepicker__week {
  @apply flex justify-around;
}

.react-datepicker__day-names {
  @apply flex justify-around text-gray-400 font-medium text-center text-xs;
}

.react-datepicker__day-name {
  @apply w-8 h-8 flex items-center justify-center py-1 rounded-full;
}

.react-datepicker__navigation {
  @apply absolute top-2;
}

.react-datepicker__navigation--previous {
  @apply right-12 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
}

.react-datepicker__navigation--next {
  @apply right-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
}

.react-datepicker__day {
  @apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-gray-700 rounded border-2 border-transparent hover:border-blue-500;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-40 hover:bg-transparent;
}

.react-datepicker__day--outside-month {
  @apply text-gray-300;
}

.react-datepicker__day--in-range {
  @apply bg-gray-200;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-blue-200;
}

.react-datepicker__day--selecting-range-start {
  @apply bg-white border-2 border-blue-500;
}

.react-datepicker__day--selecting-range-end {
  @apply bg-white border-2 border-blue-500;
}

.react-datepicker__day--selected {
  @apply bg-blue-500 text-white;
}

.react-datepicker__day--range-start {
  @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white;
}

.react-datepicker__day--range-end {
  @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white;
}

.react-datepicker--time-only {
  max-height: 300px !important;
  overflow: auto !important;
}

.react-datepicker__header--time--only {
  @apply text-base font-bold tracking-tighter;
}

.react-datepicker__time-list-item {
  @apply py-2 px-2.5 text-md tracking-tighter cursor-pointer hover:bg-primary hover:text-white;
}

.react-datepicker--time-only::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.react-datepicker--time-only::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}
.react-datepicker--time-only::-webkit-scrollbar-thumb {
  background-color: #d1d1d1;
  border-radius: 10px;
}
/* end CSS for react-datepicker */

.updating-report-status div.text-white {
  margin-right: 1rem;
}

.updating-report-status div.text-white svg.animate-spin {
  position: absolute;
  top: 0.75rem;
  right: 1.75rem;
}


.tooltip-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.tooltip-content {
  position: absolute;
  display: none;
  text-align: center;
  transition: opacity 0.3s ease-in-out;
  top: -32px;

}

.tooltip-container:hover .tooltip-content {
  display: inline-block;
}

.tooltip-inner {
  position: relative;
  display: flex;
  justify-content: center;
}

.tooltip-box {
  z-index: 20;
  font-size: 13px;
  padding: 2px 2px;
  border-radius: 4px;
  min-width: 18px;  
  width: 105px;
}

.tooltip-arrow {
  position: absolute;
  z-index: 0;
  top: 17px;
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
}
