.statistics-container {
  @apply flex flex-wrap -mx-2 overflow-hidden;
}

.statistics-card-item {
  @apply w-full bg-white px-7 py-10;
}

.dashboard-icon-container {
  @apply flex justify-center items-center;
  width: 70px !important;
  height: 70px !important;
}
