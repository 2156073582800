.page-wrapper {
  @apply h-full;
}

.main-page-wrapper {
  @apply w-full;
}

.form-page-wrapper {
  @apply relative h-screen bg-primary flex flex-col items-center justify-center;
}

.form-container {
  @apply bg-white flex flex-col items-center justify-center rounded-xl py-14 px-15
        w-10/12
        md:w-8/12
        2md:w-5/12
        1xl:w-4/12;
}

.form-logo-container {
  @apply flex justify-center;
  padding-bottom: 66px !important;
}

.form-logo {
  @apply w-full;
}

/* Column Section */
.form-column-title {
  @apply text-left pb-6 text-xl w-full font-bold
        md:pb-3
        2md:pb-8 2md:text-3xl;
}

.form-column-subtitle {
  @apply text-center text-xs pb-6 w-10/12
        md:pb-3
        2md:pb-6 2md:text-sm
        lg:pb-10 lg:text-sm
        2xl:pb-12 2xl:text-base
        3xl:pb-16 3xl:text-lg;
}

.form-column-title-req {
  @apply text-center pb-0  text-xl
        2md:text-2xl
        lg:text-3xl
        2xl:text-4xl
        3xl:text-5xl;
}

.form-column-container {
  @apply w-full;
}

.form-input-container {
  @apply flex-1 mb-6;
}

.form-input-container-notification {
  @apply flex-1 overflow-hidden p-1 mr-5;
}
.checkbox-container {
  @apply mt-7 flex flex-row justify-center items-center;
}

.forgot-password-link {
  @apply text-sm text-primary cursor-pointer font-semibold;
}

.no-account-text {
  @apply text-center font-light text-xs
        2md:text-sm
        2xl:text-lg
        3xl:text-lg;
}

.no-account-link {
  @apply text-primary cursor-pointer;
}

.checkbox-text {
  @apply text-xs font-light
        2md:text-sm
        2xl:text-lg
        3xl:text-lg;
}

.page-link {
  @apply px-2.5 py-1 bg-white
        border-2 rounded-lg border-gray
        text-sm font-bold opacity-70 cursor-pointer
        hover:bg-gray hover:border-black;
}

.page-link.active {
  @apply bg-gray border-black;
}

.left-3 {
  left: 0.75rem;
}