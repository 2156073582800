.input-wrapper {
  @apply relative;
}

.input {
  @apply w-full rounded mt-1;
}

.icon-input {
  @apply absolute text-white h-full w-full p-1;
}

.input-error-message {
  @apply text-red text-xs mb-0.5;
}

.input-class {
  @apply block w-full appearance-none outline-none bg-transparent
        lg:text-lg
        2xl:text-xl
        3xl:text-2xl;
}

.input-label {
  @apply absolute top-1.5 pl-4 text-xs opacity-50
        lg:text-xs
        2xl:text-sm
        3xl:text-lg;
}

.validation-container {
  @apply absolute right-4 rounded-full h-5 w-5
        lg:h-6
        lg:w-6
        top-3.5
        2xl:top-5;
}

/* input css */
.input-typeahead {
  @apply block w-full appearance-none outline-none bg-transparent rounded;
  border-color: #dfdfdf !important;
  border-width: 1px !important;
}

.input-typeahead:focus-visible,
.input-typeahead:focus-visible {
  outline: none !important;
  border-width: 1px !important;
}

.input-list-container {
  max-height: 205px !important;
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.1) !important;
  @apply absolute mt-2.5 w-full rounded bg-white overflow-y-auto;
}

.input-list {
  @apply cursor-pointer select-none py-3 px-4 hover:bg-gray;
}
