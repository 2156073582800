.notification-list {
    width: 400px;
    min-height: 0px;
    max-height: 700px;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.notification-list::-webkit-scrollbar {
    display: none;
}