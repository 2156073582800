/* Made this because there is no unset utility in tailwind. */
@media (max-width: 768px) {
	#two-column-layout-component .content-column {
		overflow: unset !important;
	}

	#two-column-layout-component .content-column .content-wrapper {
		overflow: unset !important;
	}
}

@media (min-width: 768px) {
    #two-column-layout-component .column-content-wrapper .column-content-text {
        font-size: 2.3vw;
    	line-height: 3vw;
    }
}